import { UserDetails } from "@/interfaces/UserDetails"
import { useLocalStorage, useReadLocalStorage } from "usehooks-ts"

const useRoles = (userDetailsData: UserDetails) => {
  let isEmployee = true
  let isAdmin = false
  let isManager = false
  let companyId = 0
  let isReviewer = false
  let userName = ""
  let isCleaner = false
  let isMaintain = false

  if (userDetailsData && !userDetailsData?.data?.service_type) {
    window.localStorage.removeItem("accessToken")
  }

  if (userDetailsData) {
    isEmployee = userDetailsData?.data?.role === "Employee"
    isAdmin = ["Admin", "GSAdmin", "SysAdmin"].includes(userDetailsData?.data?.role)
    isManager = userDetailsData?.data?.role === "Manager"
    userName = userDetailsData?.data.Full_Name
    companyId = userDetailsData?.data?.cleaningcompanies_id
    isReviewer = userDetailsData?.data?.is_task_reviewer

    if (userDetailsData?.data?.service_type) {
      isCleaner = userDetailsData?.data?.service_type === "Cleaning"
      isMaintain = userDetailsData?.data?.service_type !== "Cleaning" && userDetailsData?.data?.service_type !== ""
    }
  }

  return { isAdmin, isEmployee, companyId, userName, isReviewer, isManager, isCleaner, isMaintain }
}

export default useRoles
