export const TEXT_THEME = {
  textV1: {
    xs: "1rem",
    sm: "1rem",
    md: "1.25rem",
    lg: "1.25rem",
    xl: "1.5rem",
  },

  textV2: {
    xs: "1.25rem",
    sm: "2rem",
    md: "2rem",
    lg: "2.0rem",
    xl: "2.0rem",
  },
  textV3: {
    xs: "0.75rem",
    sm: "1rem",
    md: "1rem",
    lg: "1rem",
    xl: "1.25rem",
  },
  CV1: "clamp(1rem, 2.5vw, 1.25rem)",
  CV2: "clamp(0.75rem, 2.5vw, 1rem)",
}

export const SPACING_THEME = {
  marginBottomVariant1: {
    xs: "4rem",
    sm: "4rem",
    md: "4rem",
    lg: "4rem",
    xl: "6rem",
  },

  marginRightV1: { xs: "2rem", sm: "2rem", md: "3rem", lg: "4rem", xl: "5rem" },
  paddingPropertyInformation: {
    xs: "1.75rem",
    sm: "2rem",
    md: "3rem",
    lg: "4rem",
    xl: "4rem",
  },
}

export const IMAGE_GRID_SIZE_THEME = {
  boxArrayIcon: {
    xs: "8rem",
    sm: "9rem",
    md: "10rem",
    lg: "11rem",
    xl: "12rem",
  },

  bedConfigBox: {
    height: { xs: "6rem", sm: "7rem", md: "8rem", lg: "9rem", xl: "10rem" },
    width: {
      xs: "14rem",
      sm: "15rem",
      md: "16rem",
      lg: "17.5rem",
      xl: "17.5rem",
    },
  },

  propertyInformationImage: {
    height: { xs: "4rem", sm: "4rem", md: "4rem", lg: "4rem", xl: "4rem" },
    width: { xs: "4rem", sm: "4rem", md: "4rem", lg: "4rem", xl: "4rem" },
  },

  propertyInformationIcon: {
    height: { xs: "7.5rem", sm: "9rem", md: "10rem", lg: "10rem", xl: "10rem" },
    width: { xs: "7.5rem", sm: "9rem", md: "10rem", lg: "10rem", xl: "10rem" },
  },
}

export const COLORS = {
  textWhite: "#FFF",
  textOffWhite: "rgba(255, 255, 255, 0.7)",

  // Customer Module
  customerAppBgColor: "#1D2319",
  customerAppOutlineColor: "#3CA63C",
  customerAppOutlineSecondary: "rgba(255, 255, 255, 0.2)",
  customerAppTextPrimary: "#FFFFFFB2",
  customerAppPrimaryButton: "#3CA63C",
  customerAppSecondaryButton: "#2A7221",
  customerAppTertiaryButton: "#6F6F6F",

   // Surfaces
   surfaceDark: "#292A29",
   surfaceBlack: "#0A0A0A",
   surfaceTertiary: "#3E433B",
   surfaceSecondary: "#1D1D1D",

  // Admin App
  adminAppBgColor: "#1C2218",
  adminAppPrimaryBrandColor: "#38B000",
  adminAppPrimaryBrandColorTranslucent: "#38B00066",
  adminAppPrimaryBrandColorTranslucentPlus: "#38B0001A",
  adminAppTextPrimary: "#FFFFFFB2",
  adminAppTextSecondary: "#E7E3FC",
  adminAppOutlineColor: "#3CA63C",
  badgeBlue: "#0C6DFF",
  adminAppBgColorV2: "#272D24",
  darkGreen: "#2E5319",
  blue: "rgba(12, 109, 255, 0.3)",
  lightGreen: "rgba(58, 207, 1, 0.3)",
  primaryButton: "#1B920A",
  secondaryButton: "#2B4D1D",
  brandBlue: "#0E77DF",
  brandBlueDark: "#084887",
  menubar: "#181924",
  green: "#00BC13",
  red: "#A91818",
  newChipRed: "#B13E50",
  lightRed: "rgba(169, 24, 24, 0.2)",
  fadedRed: "#cf4f4f",
  newChipBlue: "#E7F3FE",
  yellow: "#F5A70F",
  lightYellow: "rgba(228, 146, 23, 0.2)",
  highPrior: "#E46217",
  medPrior: "#E49217",
  lowPrior: "#FFD584",
  chartPrimary: " #27293C",
  black: "#000000",
  charcoal: "#4b4b4b",
  white: "#ffffff",
  lightGrey: "rgba(234, 234, 236, 0.6)",
  neonBlue: "#0F83F5",
  lightCheap: "#F5FAFF",
  lightBlue: "#B7DBFD",
  chipCompleted: "rgba(204, 255, 0, 0.5)",
  tableBorder: "#3e433b",

  errorState: "#FE4646",
  errorStateTranslucent: "#FE464666",

  divider: "#3B4835",

  commentUserName: "#FFB400",
}
