import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios"
import { toast } from "react-toastify"

interface ApiConfiguration {
  accessToken?: string | null
}

const getAccessToken = () => {
  const token = localStorage.getItem("accessToken") || ""
  const accessToken = token.replace(/"|'/g, "")
  return accessToken
}

class ApiClient {
  private client: AxiosInstance
  public accessToken: string = ""

  constructor(apiConfiguration: ApiConfiguration) {
    this.client = this.createAxiosClient(apiConfiguration)
    if (apiConfiguration?.accessToken !== undefined && apiConfiguration?.accessToken !== null) {
      const cleanToken = (apiConfiguration?.accessToken || this.accessToken).replace(/"/g, "")
      this.client.defaults.headers.common.Authorization = `Bearer ${cleanToken}`
    } else {
      null
    }
    this.client.defaults.headers["User-Agent"] = "Web"
    this.client.defaults.headers["X-Channel"] = "EXTERNAL"
  }

  setAccessToken = (token: any) => {
    this.accessToken = token || localStorage.getItem("accessToken")
    this.client.defaults.headers.common.Authorization = `Bearer ${this.accessToken}`
  }

  protected createAxiosClient(apiConfiguration?: ApiConfiguration): AxiosInstance {
    return axios.create({
      responseType: "json" as const,
      headers: {
        "Content-Type": "application/json",
        // 'X-Branch': 'v2',
        // 'X-Branch':'SER-16-23Oct',
        "Time-Zone": Intl.DateTimeFormat().resolvedOptions().timeZone,
        ...apiConfiguration,
      },
    })
  }

  async post<TRequest, TResponse>(path: string, payload: TRequest, config?: AxiosRequestConfig): Promise<TResponse> {
    try {
      const response = config ? await this.client.post(path, payload, config) : await this.client.post(path, payload)
      return response.data
    } catch (error) {
      const err = error as AxiosError
      handleError(err)
    }
    return {} as TResponse
  }

  async get<TResponse>(path: string, config: AxiosRequestConfig = {}): Promise<TResponse> {
    try {
      const newConfig = {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
      return await this.client.get(path, newConfig)
    } catch (error) {
      if (error instanceof AxiosError) {
        const err: AxiosError = JSON.parse(JSON.stringify(error))
        handleError(err)
      }
    }
    return {} as TResponse
  }

  async patch<TRequest, TResponse>(path: string, payload: TRequest, config?: AxiosRequestConfig): Promise<TResponse> {
    try {
      const response = config ? await this.client.patch(path, payload, config) : await this.client.patch(path, payload)
      return response.data
    } catch (error) {
      if (error instanceof AxiosError) {
        const err: AxiosError = JSON.parse(JSON.stringify(error))
        handleError(err)
      }
    }
    return {} as TResponse
  }

  async put<TRequest, TResponse>(path: string, payload: TRequest, config?: AxiosRequestConfig): Promise<TResponse> {
    try {
      const response = config ? await this.client.put(path, payload, config) : await this.client.put(path, payload)
      return response.data
    } catch (error) {
      if (error instanceof AxiosError) {
        const err: AxiosError = JSON.parse(JSON.stringify(error))
        handleError(err)
      }
    }
    return {} as TResponse
  }

  async delete<TResponse>(path: string, config?: AxiosRequestConfig): Promise<TResponse> {
    try {
      const response = config ? await this.client.delete(path, config) : await this.client.delete(path)
      return response.data
    } catch (error) {
      if (error instanceof AxiosError) {
        const err: AxiosError = JSON.parse(JSON.stringify(error))
        handleError(err)
      }
    }
    return {} as TResponse
  }
}

enum StatusCode {
  Unauthorized = 401,
  Forbidden = 403,
  TooManyRequests = 429,
  InternalServerError = 500,
  NotFound = 404,
  AuthorizationFailed = 400,
  success = 412,
}

function handleError(error: AxiosError) {
  const { status, message, response } = error
  response &&
    response.data &&
    typeof response.data === "object" &&
    "message" in response.data &&
    typeof response.data.message === "string" &&
    toast.info(response.data.message, { autoClose: 1000, theme: "dark" })
  switch (status) {
    case StatusCode.InternalServerError: {
      // toast.error(message);
      break
    }
    case StatusCode.Forbidden: {
      // toast.error(message);
      break
    }
    case StatusCode.Unauthorized: {
      // toast.error(message);
      break
    }
    case StatusCode.TooManyRequests: {
      // toast.error(message);
      break
    }
    case StatusCode.NotFound: {
      // toast.error(message);
      break
    }
    case StatusCode.AuthorizationFailed: {
      // toast.error(message);
      break
    }
    case StatusCode.success: {
      // toast.error(message);
      break
    }
    default: {
      // toast.error(message);
      break
    }
  }

  throw error
}

var profileApiConfig: ApiConfiguration = {}
profileApiConfig.accessToken = typeof window !== "undefined" || null ? localStorage.getItem("accessToken") : undefined
export const apiClient = new ApiClient(profileApiConfig)
