const HOST_API_KEY = process.env.NEXT_PUBLIC_HOST_API_KEY || ""
const APP_ENVIRONMENT = process.env.NEXT_PUBLIC_NODE_ENV
const HOST_FIREBASE_CONFIG = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MESSUREMENT_ID,
}

export { HOST_API_KEY, APP_ENVIRONMENT, HOST_FIREBASE_CONFIG }
