import { useQuery } from "react-query"
import { apiClient } from "../apiClient/apiService"
import ApiEndpoints, { ROUTES } from "../apiClient/apiEndpoints"
import { UserDetails } from "@/interfaces/UserDetails"

const useUserDetails = () => {
  return useQuery<{ data: UserDetails }>({
    queryKey: ["userDetails"],
    queryFn: () => {
      return apiClient.get(ApiEndpoints.GET[ROUTES.getUserDetails])
    },
    onSuccess: (data) => {},
    refetchOnWindowFocus: false,
  })
}

export default useUserDetails
